import { vipStore } from './vip.store';
import { xmasStore } from './xmas.store';
import { valentineStore } from './valentine.store';

export const GlobalStore = {
  VIP_STORE: vipStore,
  XMAS_STORE: xmasStore,
  VALENTINE_STORE: valentineStore,
};

export enum GlobalStoreName {
  VIP_STORE = 'VIP_STORE',
  XMAS_STORE = 'XMAS_STORE',
  VALENTINE_STORE = 'VALENTINE_STORE',
}
